import React from 'react';
import styled from 'styled-components';

import BackgroundSection from '../components/background-section';
import CallToAction from '../components/call-to-action';
import Content from '../components/content-404';
import Footer from '../components/footer';
import Header from '../components/header';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Switcher from '../components/switcher';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Header color='#ffa799' />
    <main>
      <StyledBackgroundSection>
        <Content />
      </StyledBackgroundSection>
      <CallToAction primaryColor="#e71f00" />
    </main>
    <Footer />
    <Switcher />
  </Layout>
);

const StyledBackgroundSection = styled(BackgroundSection)`
  padding-top: 10rem;
`;

export default NotFoundPage;

import React from 'react';
  import styled from 'styled-components';
import { Offset } from '../elements/layout';
import Meta from './meta';
import H1 from './h1';
import Bar from './content/bar';
import Article from './content/article';

const Content = (props) => {
  return (
    <Container>
      <Offset>
        <Wrap color="#f0f0f0">
          <ContentWrap>
            <H1>Nothing Found</H1>
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
          </ContentWrap>
        </Wrap>
      </Offset>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const Wrap = styled.div`
  padding-bottom: 10rem;
  background: ${({ color }) => color};
`;

const ContentWrap = styled.div`
  border-top: 5px solid #e71f00;
  padding: 1.5rem 1rem;

  @media (min-width: 415px) {
    padding: 1.5rem;
  }

  @media (min-width: 530px) {
    padding: 2rem 3.75rem 3.75rem;
  }

  @media (min-width: 768px) {
    padding: 3.75rem 3.75rem 6.5rem;
  }
`;

export default Content;
